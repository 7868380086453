import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Typography, Grid } from '@/components/atoms';

import mq from '@/styles/mq';

import { PrismicContactBox } from '@/domain/PrismicContactBox';
import PrismicComponent from '@/prismic/prismicComponent';

interface ContactBoxProps {
  lang: string;
}

interface StaticQuery {
  allPrismicContactBox: {
    edges: {
      node: PrismicContactBox;
    }[];
  };
}

const ContactBoxRoot = styled.section`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  position: relative;
  /* display: flex; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 100%;
  ${mq('md')} {
    border-radius: 20px;
    width: 90%;
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
  ${mq('lg')} {
    width: 80%;
    margin-top: ${({ theme }) => theme.spacing(10)};
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const ParallaxContainer = styled.div`
  width: 100%;
  height: 650px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  ${mq('xxs')} {
    height: 520px;
  }
  ${mq('xs')} {
    height: 500px;
  }
  ${mq('md')} {
    height: 400px;
    border-radius: 20px;
  }
  ${mq('lg')} {
    height: 400px;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  ${mq('md')} {
    border-radius: 20px;
  }
`;

const InfosSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  border-bottom: 1px solid ${({ theme }) => theme.color.white.dark};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${mq('xxs')} {
    height: 50%;
    width: 100%;
  }
  ${mq('md')} {
    border-right: 1px solid ${({ theme }) => theme.color.white.dark};
    border-bottom: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 50%;
    height: 100%;
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('lg')} {
    width: 50%;
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const InfosContent = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)};
  ${mq('xxs')} {
    margin-left: ${({ theme }) => theme.spacing(0)};
  }
`;

const InfosTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.white.light};
  text-align: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('xxs')} {
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
  ${mq('md')} {
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

const InfosPlace = styled(PrismicComponent)`
  p {
    color: ${({ theme }) => theme.color.white.main};
  }
`;

const InfosNum = styled(Typography)`
  color: ${({ theme }) => theme.color.white.main};
`;

const InfosMail = styled(PrismicComponent)`
  p {
    color: ${({ theme }) => theme.color.white.main};
  }
`;

const HorairesSection = styled.div`
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${mq('xxs')} {
    width: 100%;
    height: 50%;
    bottom: 0;
    right: 0;
  }
  ${mq('md')} {
    top: 0;
    right: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 50%;
    height: 100%;
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('lg')} {
    width: 50%;
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
const HoraireTitle = styled(Typography)`
  width: 100%;
  color: ${({ theme }) => theme.color.white.light};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('xxs')} {
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('md')} {
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }
  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;

const HoraireContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 90%;
  ${mq('xxs')} {
    height: 70%;
  }
  ${mq('xs')} {
    height: 60%;
  }
  ${mq('md')} {
    height: 45%;
  }
  ${mq('lg')} {
    height: 50%;
  }
`;

const HoraireContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 0.5px solid white;
  padding: ${({ theme }) => theme.spacing(0.5)};
  width: 50%;
  ${mq('xxs')} {
    padding: ${({ theme }) => theme.spacing(1)};
    width: 40%;
  }
  ${mq('xs')} {
    padding: ${({ theme }) => theme.spacing(1)};
    width: 30%;
  }
  ${mq('md')} {
    padding: ${({ theme }) => theme.spacing(1)};
    width: 40%;
  }
  ${mq('lg')} {
    padding: ${({ theme }) => theme.spacing(1)};
    width: 40%;
  }
`;

const HoraireDay = styled(Typography)`
  color: ${({ theme }) => theme.color.white.light};
  ${mq('md')} {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
  ${mq('lg')} {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`;

const HoraireHour = styled(Typography)`
  color: ${({ theme }) => theme.color.white.light};
`;

export const ContactBox = ({ lang }: ContactBoxProps): JSX.Element => {
  const data = useStaticQuery<StaticQuery>(query);
  const [{ node }] = data?.allPrismicContactBox?.edges?.filter(
    (item) => item?.node?.lang === lang,
  );

  return (
    <ContactBoxRoot>
      <ParallaxContainer
        css={css`
          background-image: url(${node?.data?.background_image?.url});
        `}
      >
        <InfosSection>
          <InfosTitle variant="h3" as="p">
            {node?.data?.title_infos?.text}
          </InfosTitle>
          <InfosContent>
            <InfosPlace content={node?.data?.place?.raw} />
            <InfosNum variant="textSm" as="p">
              {node?.data?.phone?.text}
            </InfosNum>
            <InfosMail content={node?.data?.mail?.raw} />
          </InfosContent>
        </InfosSection>
        <HorairesSection>
          <HoraireTitle variant="h3" as="p">
            {node?.data?.schedules_title?.text}
          </HoraireTitle>
          <HoraireContent>
            {node?.data?.schedules?.map((schedule) => (
              <HoraireContainer>
                <HoraireDay variant="textSm" as="p">
                  {schedule?.day}
                </HoraireDay>
                <HoraireHour variant="textSm" as="p">
                  {schedule?.hours}
                </HoraireHour>
              </HoraireContainer>
            ))}
          </HoraireContent>
        </HorairesSection>
      </ParallaxContainer>
      <Overlay />
    </ContactBoxRoot>
  );
};

const query = graphql`
  query ContactBox {
    allPrismicContactBox {
      edges {
        node {
          lang
          data {
            background_image {
              url
            }
            title_infos {
              text
            }
            place {
              raw
            }
            phone {
              text
            }
            mail {
              raw
            }
            schedules_title {
              text
            }
            schedules {
              day
              hours
            }
          }
        }
      }
    }
  }
`;
